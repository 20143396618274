// ===
// Overrides
// ===

#nprogress .bar {
  background: $primary;
}

.sentry-error-embed-wrapper button.btn {
  background: $primary !important;
  border: none !important;
  border-radius: 4px;
}

// ===
// Filepond
// ===

div.filepond--wrapper {
  div.filepond--root {
    z-index: 0;
    margin: 0 auto;

    div.filepond--drop-label {
      border-radius: 8px;
    }
  }
}

.filepond--action-process-item {
  z-index: 8;
  visibility: hidden;
}

.filepond--action-remove-item {
  z-index: 8;
  visibility: hidden;
}

.filepond--drop-label.filepond--drop-label label:hover {
  cursor: pointer;
}

.filepond-avatar:hover {
  cursor: pointer;

  .filepond--action-remove-item {
    visibility: visible;
  }
}

.filepond--file-action-button.filepond--action-remove-item {
  visibility: visible;
}

// ===
// Vuetify mods
// ===

div.container,
div.row {
  margin: 0;
}

// v-select__mod reduces the space in select modifier for dynamic appearance
div.v-select__mod--input-shrink {
  div.v-input__control
    div.v-input__slot
    div.v-select__slot
    div.v-select__selections
    input {
    max-width: 11px;
  }
}

// Remove unneccessary space for a cleaner look.
div.v-select__mod--input-remove {
  div.v-input__control
    div.v-input__slot
    div.v-select__slot
    div.v-select__selections {
    div.v-select__selection {
      min-width: fit-content;
    }

    input {
      display: none;
    }
  }
}
div.v-select__mod--inline-block {
  display: inline-block;
}

div.v-select__mod--solo-color-override {
  div.v-input__control div.v-input__slot {
    background-color: unset !important;
  }
}

// date-picker__mod sets title and date selected to primary color
div.date-picker__mod {
  div.v-picker__title {
    background-color: $primary;
    border-color: $primary;
  }
  div.v-picker__body {
    div.v-date-picker-table table tbody tr td button.accent {
      background-color: $primary;
      border-color: $primary;
    }
  }
}

div.v-textarea__mod {
  padding-top: 0;
  font-size: 14px;

  &.v-text-field.v-text-field--enclosed > .v-input__control > .v-input__slot {
    padding: 0;
    textarea {
      margin-top: 0;
    }
  }
  div.v-input__control {
    div.v-input__slot {
      padding: 0;
    }
  }
}

// A random min-height of 48px was added to v-text-fields that crossed over to v-selects in the vuetify upgrade.
.v-select .v-input__control {
  min-height: initial !important;
}

span.v-chip {
  margin-right: 4px;
}

// v-chip modifier on hover to display the correct cursor
span.v-chip__mod-hover:hover {
  opacity: 0.8;
  .v-chip__content {
    cursor: pointer;
  }
}

p.error,
li.error,
.error--text,
.theme--light.v-messages.error--text {
  color: red;
}

div.v-application p.error,
div.v-application li.error {
  background-color: transparent !important;
  border-color: transparent !important;
  color: red !important;
}

.is-read-only {
  &.v-input--slider {
    cursor: not-allowed;
  }
  .v-input__control > .v-input__slot {
    cursor: not-allowed !important;
    textarea,
    input,
    label.v-label {
      cursor: not-allowed;
    }
    .v-input--selection-controls__input {
      cursor: not-allowed;
      .v-input--selection-controls__ripple {
        cursor: not-allowed;
      }
    }
    .v-input--radio-group__input > .v-radio {
      div,
      label {
        cursor: not-allowed;
      }
    }
  }
}

div.no-results.v-list-item.v-list-item:not(.v-list-item--active):not(
    .v-list-item--disabled
  ) {
  font-size: 14px;
  color: rgba(0, 0, 0, 0.6) !important;

  &:hover {
    background-color: unset !important;
    cursor: unset;
  }
}

// v-tabs/v-tab Material Design overrides
// The following material spec breaks most mobile styling of tabs: https://material.io/components/tabs/#scrollable-tabs
// The following optional CSS hacks will override the base functionality of v-tabs to behave as we expect them to behave.
div.no-slide-tabs {
  .v-slide-group__prev--disabled {
    display: none !important;
  }
  .v-slide-group__content {
    transform: none !important;
  }
}

// Global tab styles
.v-tab:not(.workflow-step) {
  font-weight: 600 !important;
  font-size: 14px;
  line-height: 18px;
  letter-spacing: 1px;
  text-transform: uppercase !important;
  padding: 0 8px !important;
}

.base-subnav-tabs {
  background: $background;
  margin-bottom: 16px;

  .v-slide-group__prev,
  .v-slide-group__next {
    @media (min-width: 500px) {
      display: none !important;
    }
  }

  .base-subnav-tab.v-tab,
  .base-subnav-tab.v-tab {
    font-size: 18px;
    line-height: 24px;
    color: $black-medium-emphasis !important;
    text-transform: capitalize;
    letter-spacing: normal;
    margin-right: 8px;
    min-width: unset;
    padding: 0 4px;
    border-bottom: 2px solid transparent;

    &::before {
      display: none;
    }

    &:hover {
      color: $primary !important;

      .v-icon {
        color: $primary;
      }
    }

    &.v-tab--active {
      color: $primary !important;
      border-bottom-color: $primary;

      .v-icon {
        color: $primary;
      }
    }
  }
}

// Quick Actions and Data Source bg color
.data-source-gradient {
  background: linear-gradient(180deg, #1c1836 0%, #453e60 100%) !important;
}

// Tooltip Global SCSS Mods
.v-tooltip__content {
  background: rgba(0, 0, 0, 1) !important;
  span.tooltip-text-break-word {
    word-wrap: break-word;
  }
}

div.v-overlay {
  $value: 202;

  @for $i from 1 through 5 {
    &:nth-child(#{$i}) {
      z-index: $value !important;
      $value: $value + 1;
    }
  }
}

// ===
// Vuetify base typography classes
// ===

/* H1 */
.display-4 {
  font-size: 96px;
  font-weight: 600;
  line-height: 104px;
  font-family: "Source Sans Pro", sans-serif;
  letter-spacing: -1.5px;
}

/* H2 */
.display-3 {
  font-size: 60px;
  font-weight: 400;
  letter-spacing: -0.5px;
  line-height: 68px;
  font-family: "Source Sans Pro", sans-serif;
}

/* H3 */
.display-2 {
  font-size: 48px;
  font-weight: 400;
  letter-spacing: -0.5px;
  line-height: 56px;
  font-family: "Source Sans Pro", sans-serif;
}

/* H4 */
.display-1 {
  font-size: 34px;
  font-weight: 400;
  letter-spacing: -0.5px;
  line-height: 42px;
  font-family: "Source Sans Pro", sans-serif;
}

/* H5 */
.headline {
  font-size: 24px;
  font-weight: 400;
  letter-spacing: -0.5px;
  line-height: 104px;
  font-family: "Source Sans Pro", sans-serif;
}

/* H6 */
.title {
  font-size: 20px;
  font-weight: 400;
  letter-spacing: 0px;
  line-height: 28px;
  font-family: "Source Sans Pro", sans-serif;
}

/* Subtitle 1 */
.subtitle-1 {
  font-size: 16px;
  font-weight: 400;
  letter-spacing: 0px;
  line-height: 24px;
  font-family: "Source Sans Pro", sans-serif;
}

/* Subtitle 2 */
.subtitle-2 {
  font-size: 14px;
  font-weight: 400;
  letter-spacing: 0px;
  line-height: 22px;
  font-family: "Source Sans Pro", sans-serif;
}

/* Body 1 */
.body-1 {
  font-size: 16px;
  font-weight: 400;
  letter-spacing: 0px;
  line-height: 24px;
  font-family: "Source Sans Pro", sans-serif;
}

/* Body 2 */
.body-2 {
  font-size: 14px;
  font-weight: 400;
  letter-spacing: 0px;
  line-height: 22px;
  font-family: "Source Sans Pro", sans-serif;
}

/* Caption */
.caption {
  font-size: 12px;
  font-weight: 400;
  letter-spacing: 0.5px;
  line-height: 16px;
  font-family: "Source Sans Pro", sans-serif;
}

/* Overline */
.overline {
  font-size: 12px;
  font-weight: 700;
  letter-spacing: 1.5px;
  line-height: 16px;
  text-transform: uppercase;
  font-family: "Source Sans Pro", sans-serif;
}
