// ===
// Google Fonts, download for performance
// https://www.tunetheweb.com/blog/should-you-self-host-google-fonts/
// https://gwfh.mranftl.com/fonts
// ===

/* source-sans-pro-300 - latin-ext_latin */
@font-face {
  font-family: "Source Sans Pro";
  font-style: normal;
  font-weight: 300;
  font-display: swap;
  unicode-range: U+000-5FF; /* Latin glyphs */
  src:
    local("Source Sans Pro Light"),
    local("SourceSansPro-Light"),
    url("assets/fonts/source-sans-pro-v13-latin-ext_latin-300.woff2")
      format("woff2"),
    /* Chrome 26+, Opera 23+, Firefox 39+ */
      url("assets/fonts/source-sans-pro-v13-latin-ext_latin-300.woff")
      format("woff"); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}

/* source-sans-pro-regular - latin-ext_latin */
@font-face {
  font-family: "Source Sans Pro";
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  unicode-range: U+000-5FF; /* Latin glyphs */
  src:
    local("Source Sans Pro Regular"),
    local("SourceSansPro-Regular"),
    url("assets/fonts/source-sans-pro-v13-latin-ext_latin-regular.woff2")
      format("woff2"),
    /* Chrome 26+, Opera 23+, Firefox 39+ */
      url("assets/fonts/source-sans-pro-v13-latin-ext_latin-regular.woff")
      format("woff"); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}
/* source-sans-pro-600 - latin-ext_latin */
@font-face {
  font-family: "Source Sans Pro";
  font-style: normal;
  font-weight: 600;
  font-display: swap;
  unicode-range: U+000-5FF; /* Latin glyphs */
  src:
    local("Source Sans Pro SemiBold"),
    local("SourceSansPro-SemiBold"),
    url("assets/fonts/source-sans-pro-v13-latin-ext_latin-600.woff2")
      format("woff2"),
    /* Chrome 26+, Opera 23+, Firefox 39+ */
      url("assets/fonts/source-sans-pro-v13-latin-ext_latin-600.woff")
      format("woff"); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}
/* source-sans-pro-700 - latin-ext_latin */
@font-face {
  font-family: "Source Sans Pro";
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  unicode-range: U+000-5FF; /* Latin glyphs */
  src:
    local("Source Sans Pro Bold"),
    local("SourceSansPro-Bold"),
    url("assets/fonts/source-sans-pro-v13-latin-ext_latin-700.woff2")
      format("woff2"),
    /* Chrome 26+, Opera 23+, Firefox 39+ */
      url("assets/fonts/source-sans-pro-v13-latin-ext_latin-700.woff")
      format("woff"); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}

/* lora-regular - latin-ext_latin */
@font-face {
  font-family: "Lora";
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  unicode-range: U+000-5FF; /* Latin glyphs */
  src:
    local("Lora Regular"),
    local("Lora-Regular"),
    url("assets/fonts/lora-v32-latin_latin-ext-regular.woff2") format("woff2"),
    /* Chrome 26+, Opera 23+, Firefox 39+ */
      url("assets/fonts/lora-v32-latin_latin-ext-regular.woff") format("woff"); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}

/* Inter - regular - latin-ext_latin */
@font-face {
  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  unicode-range: U+000-5FF; /* Latin glyphs */
  src:
    local("Inter Regular"),
    local("Inter-Regular"),
    url("assets/fonts/Inter-Regular.woff2") format("woff2"),
    /* Chrome 26+, Opera 23+, Firefox 39+ */
      url("assets/fonts/Inter-Regular.woff") format("woff"); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}

/* Inter - medium - latin-ext_latin */
@font-face {
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-display: swap;
  unicode-range: U+000-5FF; /* Latin glyphs */
  src:
    local("Inter Medium"),
    local("Inter-Medium"),
    url("assets/fonts/Inter-Medium.woff2") format("woff2"),
    /* Chrome 26+, Opera 23+, Firefox 39+ */
      url("assets/fonts/Inter-Medium.woff") format("woff"); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}

/* Inter - semi-bold - latin-ext_latin */
@font-face {
  font-family: "Inter";
  font-style: normal;
  font-weight: 600;
  font-display: swap;
  unicode-range: U+000-5FF; /* Latin glyphs */
  src:
    local("Inter Semi Bold"),
    local("Inter-Semi-Bold"),
    url("assets/fonts/Inter-SemiBold.woff2") format("woff2"),
    /* Chrome 26+, Opera 23+, Firefox 39+ */
      url("assets/fonts/Inter-SemiBold.woff") format("woff"); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}

/* IBM Plex Sans - regular - latin-ext_latin */
@font-face {
  font-family: "IBM Plex Sans";
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  unicode-range: U+000-5FF; /* Latin glyphs */
  src:
    local("IBM Plex Sans Regular"),
    local("IBM-Plex-Sans-Regular"),
    url("assets/fonts/IBMPlexSans-Regular.woff2") format("woff2"),
    /* Chrome 26+, Opera 23+, Firefox 39+ */
      url("assets/fonts/IBMPlexSans-Regular.woff") format("woff"); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}

// ===
// Google Icons, download for performance
// https://google.github.io/material-design-icons/#getting-icons
// https://github.com/google/material-design-icons/issues/205
// ===

@font-face {
  font-family: "Material Icons";
  font-style: normal;
  font-weight: 400;
  src: url("assets/icons/material-icons.woff2") format("woff2");
}

.material-icons {
  font-family: "Material Icons";
  font-weight: normal;
  font-style: normal;
  font-size: 24px;
  line-height: 1;
  letter-spacing: normal;
  text-transform: none;
  display: inline-block;
  white-space: nowrap;
  word-wrap: normal;
  direction: ltr;
  -webkit-font-feature-settings: "liga";
  -webkit-font-smoothing: antialiased;
}

div.theme--light.v-application {
  background: $background-light;

  &.authenticated-layout {
    main.v-main {
      div#core-wrapper {
        background: #ffffff;
        overflow: hidden;

        @media (min-width: 960px) {
          margin: 16px 16px 16px 8px;
          min-height: calc(100vh - 32px);
          box-shadow:
            0px 1px 2px 0px rgba(0, 0, 0, 0.1),
            0px 1px 3px 0px rgba(0, 0, 0, 0.1);
          border-radius: 8px;

          &.share-token {
            margin-left: 16px;
          }
        }

        @media (max-width: 960px) {
          margin: 0;
          min-height: calc(100vh - 44px);
        }

        div.top-container {
          padding: 16px;
        }

        &.cv-files {
          // Fallback to chrome styles
          background-color: #323639;

          &.chrome {
            background-color: #323639;
          }

          &.edge {
            background-color: #3b3b3b;
          }

          &.safari {
            background-color: #808080;
          }

          &.firefox {
            background-color: #38383d;
          }
        }
      }
    }
  }
}

// ===
// Base app styles
// ===
*,
*::before,
*::after {
  box-sizing: border-box;
}

body {
  background: $background-light;

  div#app {
    @extend %body-font;
  }
}

// ===
// Base element styles
// ===

div.no-padding {
  padding: 0;
}

a {
  color: $primary;
  text-decoration: none;
  transition: color $duration-animation-base;

  &:hover {
    color: $primary-900;
  }
}

button {
  @extend %button;

  &.v-btn.v2-btn {
    font-weight: 400 !important;
    font-size: 14px;
    line-height: 20px;
    letter-spacing: normal;
    text-transform: capitalize !important;
    border: unset;
    border-radius: 4px;
    padding: 6px 8px !important;
    color: rgba(0, 0, 0, 0.6);
    background: #fafafa !important;
    box-shadow:
      0px 1px 3px 0px rgba(0, 0, 0, 0.1),
      0px 1px 2px 0px rgba(0, 0, 0, 0.06) !important;
    transition: background 0.2s;
    height: 32px !important;

    &:before,
    &:after {
      display: none;
    }

    &.is-active,
    &:hover {
      background: rgba(0, 0, 0, 0.08) !important;
    }

    i {
      height: 20px !important;
      width: 20px !important;
    }
  }

  &.v-btn {
    margin: 0 0 0 8px;
    letter-spacing: 0;
    text-transform: uppercase;

    &:first-of-type {
      margin-left: 0;
    }

    &.has-icon {
      padding-left: 10px !important;
    }

    &.has-icon-right {
      padding-right: 10px !important;
    }

    .button-icon {
      margin-right: 8px;
    }
  }

  &.theme--light.v-btn:not(.v-btn--icon):not(.v-btn--flat) {
    font-weight: 600;
    text-transform: uppercase;
    border-radius: 4px;
    box-shadow: unset;

    &.primary {
      color: white;
      background-color: $primary;
    }

    &.primary-2 {
      color: $primary;
      background-color: rgba(0, 0, 0, 0);
    }

    &.primary-3 {
      color: $primary;
      border: 1px solid #dcdcdc;
    }

    &.nonAction {
      color: grey;
      background-color: rgba(0, 0, 0, 0.12);
    }

    &.icon {
      font-size: 18px;
      background-color: rgba(0, 0, 0, 0);
      border-radius: 50%;
    }

    &.caution {
      color: white;
      background-color: $caution;
    }

    &.caution-2 {
      color: $caution;
      background-color: #fff;
    }

    &.cancel-button {
      color: $primary;
      background-color: white;
      font-weight: 600;
    }

    &#enable-button {
      color: white;
      background-color: #4caf50 !important;
    }

    &#delete-button,
    &#disable-button,
    &#remove-button {
      color: white;
      background-color: #ff5151 !important;

      &:disabled {
        background-color: #9d9d9d !important;
        color: white !important;
      }
    }

    &#cancel,
    &#cancel-button {
      color: $primary;
    }

    &#create-button,
    &#edit-button,
    &#filter-button,
    &#duplicate-button,
    &#share-button,
    &#export-button,
    &#download-button,
    &#confirm-button,
    &#save-button,
    &#add-model-button {
      color: white;
      background-color: $primary;
    }
  }

  &:hover {
    cursor: pointer;
  }
}

caption {
  @extend %caption;
}

h1 {
  @extend %h1;
}

h2 {
  @extend %h2;
}

h3 {
  @extend %h3;
}

h4 {
  @extend %h4;
}

h5 {
  @extend %h5;
}

h6 {
  @extend %h6;
}

.blur-effect {
  -webkit-filter: blur(5px);
  -moz-filter: blur(5px);
  -o-filter: blur(5px);
  -ms-filter: blur(5px);
  filter: blur(5px);
}

::-webkit-input-placeholder {
  color: $font-color-placeholder;
}

::-moz-placeholder {
  color: $font-color-placeholder;
}

::-ms-placeholder {
  color: $font-color-placeholder;
}

::placeholder {
  color: $font-color-placeholder;
}

// ===
// Base style helpers
// ===

.mobile-hide {
  @media (max-width: $breakpoint-mobile) {
    display: none;
  }
}

span.comma:last-of-type {
  display: none;
}
