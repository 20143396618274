// ===
// Background
// ===

$background: #f7f7f9;
$background-light: #f7f7f9;
$background-dark: #e8e8e8;
$background-input: rgba(0, 0, 0, 0.1);

// ===
// Defaults
// ===

$primary: #1c1836;
$primary-900: #190078;
$primary-800: #280096;
$primary-700: #3700b3;
$primary-600: #4b01d1;
$primary-500: #6202ee;
$primary-400: #7f22fd;
$primary-300: #9e54fc;
$primary-200: #bb86fc;
$primary-100: #d7b7fd;
$primary-50: #f2e7fe;

$secondary: #01a39d;
$secondary-900: #002f33;
$secondary-800: #004b4f;
$secondary-700: #00696b;
$secondary-600: #018786;
$secondary-500: #01a39d;
$secondary-400: #02beb2;
$secondary-300: #03dac6;
$secondary-200: #18fde1;
$secondary-100: #4ffee4;
$secondary-50: #86ffea;

$caution: #ff5151;

// Default blends
$black-medium-emphasis: rgba(0, 0, 0, 0.6);

// ===
// Emphasis
// ===

$dark-emphasis-high: 0.87;
$dark-emphasis-medium: 0.6;
$dark-emphasis-disabled: 0.38;

$light-emphasis-high: 1;
$light-emphasis-medium: 0.7;
$light-emphasis-disabled: 0.5;

// ===
// Font
// ===

$font-color: rgba(0, 0, 0, 0);
$font-color-high: fade-in($font-color, $dark-emphasis-high);
$font-color-medium: fade-in($font-color, $dark-emphasis-medium);
$font-color-disabled: fade-in($font-color, $dark-emphasis-disabled);
$font-color-placeholder: $font-color-disabled;
$font-color-light: #fff;

$duration-animation-base: 200ms;

$elevation-0dp: unset;
$elevation-1dp: 0 1px 3px rgba(0, 0, 0, 0.2), 0 2px 2px rgba(0, 0, 0, 0.12),
  0 0 2px rgba(0, 0, 0, 0.14);
$elevation-2dp: 0 1px 5px rgba(0, 0, 0, 0.2), 0 3px 4px rgba(0, 0, 0, 0.12),
  0 2px 4px rgba(0, 0, 0, 0.14);
$elevation-3dp: 0 1px 8px rgba(0, 0, 0, 0.2), 0 3px 4px rgba(0, 0, 0, 0.12),
  0 3px 3px rgba(0, 0, 0, 0.14);
$elevation-4dp: 0 1px 10px rgba(0, 0, 0, 0.2), 0 4px 5px rgba(0, 0, 0, 0.12),
  0 2px 4px rgba(0, 0, 0, 0.14);
$elevation-6dp: 0 3px 5px rgba(0, 0, 0, 0.2), 0 1px 18px rgba(0, 0, 0, 0.12),
  0 6px 10px rgba(0, 0, 0, 0.14);
$elevation-8dp: 0 4px 5px rgba(0, 0, 0, 0.2), 0 3px 14px rgba(0, 0, 0, 0.12),
  0 8px 10px rgba(0, 0, 0, 0.14);
$elevation-9dp: 0 5px 6px rgba(0, 0, 0, 0.2), 0 3px 16px rgba(0, 0, 0, 0.12),
  0 9px 12px rgba(0, 0, 0, 0.14);
$elevation-12dp: 0 7px 8px rgba(0, 0, 0, 0.2), 0 5px 22px rgba(0, 0, 0, 0.12),
  0 12px 17px rgba(0, 0, 0, 0.14);
$elevation-16dp: 0 8px 10px rgba(0, 0, 0, 0.2), 0 6px 30px rgba(0, 0, 0, 0.12),
  0 16px 24px rgba(0, 0, 0, 0.14);
$elevation-24dp: 0 11px 15px rgba(0, 0, 0, 0.2), 0 9px 46px rgba(0, 0, 0, 0.12),
  0 24px 38px rgba(0, 0, 0, 0.14);

$system-default-font-family: "Source Sans Pro", sans-serif;

// Body Font
// Source Sans Pro (downloaded from Google Fonts)
// 300 - light
// 400 - regular
// 600 - semi-bold
// 700 - bold

// Heading Font
// Source Sans Pro (downloaded from Google Fonts)
// 400 - regular
// 600 - semi-bold

// Other Fonts
// Lora (downloaded from Google Fonts)
// 400 - regular

// Inter
// 400 - regular
// 500 - medium
// 600 - semi-bold

// IBM Plex-Sans
// 400 - regular

// ===
// Heading
// ===

$heading-font-family: "Source Sans Pro", sans-serif;
$heading-font-size: 20px;
$heading-font-weight: 400;
$heading-letter-spacing: 0;
$heading-line-height: normal;

%heading-font {
  font-family: $heading-font-family;
  font-size: $heading-font-size;
  font-weight: $heading-font-weight;
  line-height: $heading-line-height;
  letter-spacing: $heading-letter-spacing;
  text-rendering: optimizeLegibility;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

%h1 {
  @extend %heading-font;

  font-size: 96px;
  line-height: 144px;
  letter-spacing: -1.5px;
}

%h2 {
  @extend %heading-font;

  font-size: 60px;
  line-height: 90px;
  letter-spacing: -0.5px;
}

%h3 {
  @extend %heading-font;

  font-size: 48px;
  line-height: 72px;
  letter-spacing: 0;
}

%h4 {
  @extend %heading-font;

  font-size: 34px;
  line-height: 51px;
  letter-spacing: 0.25px;
}

%h5 {
  @extend %heading-font;

  font-size: 24px;
  line-height: 36px;
  letter-spacing: 0;
}

%h6 {
  @extend %heading-font;

  font-size: 20px;
  font-weight: 600;
  line-height: 30px;
  letter-spacing: 0.25px;
}

// ===
// Body
// ===

$body-font-family: $system-default-font-family;
$body-font-size: 20px;
$body-font-weight: 400;
$body-line-height: 16px;
$body-letter-spacing: 0;

%body-font {
  font-family: $body-font-family;
  font-size: $body-font-size;
  font-weight: $body-font-weight;
  line-height: $body-line-height;
  letter-spacing: $body-letter-spacing;
  text-rendering: optimizeLegibility;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

%body-1 {
  @extend %body-font;

  font-size: 18px;
  line-height: 24px;
  letter-spacing: 0.5px;
}

%body-2 {
  @extend %body-font;

  font-size: 14px;
  line-height: 21px;
  letter-spacing: 0.25px;
}

%subtitle-1 {
  @extend %body-font;

  font-size: 16px;
  line-height: 24px;
  letter-spacing: 0.15px;
}

%subtitle-2 {
  @extend %body-font;

  font-size: 14px;
  line-height: 21px;
  letter-spacing: 0.1px;
}

%button {
  @extend %body-font;

  font-size: 14px;
  font-weight: 700;
  line-height: 21px;
  text-transform: uppercase;
  letter-spacing: 1.25px;
}

%caption {
  @extend %body-font;

  font-size: 12px;
  line-height: 18px;
  letter-spacing: 0.4px;
}

%overline {
  @extend %body-font;

  font-family: "Source Sans Pro Bold";
  font-size: 12px;
  font-weight: 700;
  line-height: 18px;
  text-transform: uppercase;
  letter-spacing: 2px;
}

// Layouts
// xsmall - 600 width, 4 column, 16 gutter, 32 margin
// small  - 960 width, 4 column, 16 gutter, 32 margin
// medium - tbd
// large  - 1264 width, 12 column, 24 gutter, 24 margin
// xlarge - 1904 width, 12 column, 24 gutter, relative margin

// xs3 sm4 md6 lg12 xl12

// ===
// Columns
// ===

$column-xsmall: 4;
$column-small: 8;
// $column-medium: tbd;
$column-large: 12;
$column-xlarge: 12;

// ===
// Gutters
// ===

$gutter-xsmall: 16;
$gutter-small: 24;
// $gutter-medium: tbd;
$gutter-large: 24;
$gutter-xlarge: 24;

// ===
// Margins
// ===

$margin-xsmall: 32px;
$margin-small: 24px;
// $margin-medium: tbd;
$margin-large: 24px;
$margin-xlarge: relative;

// ===
// Breakpoints (width)
// ===

$breakpoint-xsmall: 600px;
$breakpoint-small: 960px;
// $breakpoint-medium: tbd;
$breakpoint-large: 1264px;
$breakpoint-xlarge: 1904px;

// ===
// Media
// ===
$breakpoint-mobile: 960px;
$breakpoint-xs-mobile: 600px;

// ===
// Spacing after DOM elements
// ===

$one-break: 20px;
$two-breaks: 40px;
$three-breaks: 60px;

div.markdown,
div.markdown-editor-wrapper,
div.markdown-editor-content div.ProseMirror {
  word-wrap: break-word;

  > h1,
  h2,
  h3,
  h4,
  h5 {
    padding-top: 16px;
    padding-bottom: 8px;
    font-style: normal;
    font-weight: 400;
    font-family: "Lora", sans-serif;
    color: rgba(0, 0, 0, 0.87);

    &:first-of-type {
      margin-top: unset;
      margin-bottom: unset;
    }
  }

  &.markdown-comment {
    > h1,
    h2,
    h3,
    h4,
    h5 {
      font-family: "Source Sans Pro", sans-serif !important;
    }

    div.markdown-editor-content div.ProseMirror {
      > h1,
      h2,
      h3,
      h4,
      h5 {
        font-family: "Source Sans Pro", sans-serif !important;
      }
    }
  }

  /* H1 */
  h1,
  .display-4 {
    font-size: 96px;
    line-height: 96px;
    letter-spacing: -2px;
  }

  /* H2 */
  h2,
  .display-3 {
    font-size: 60px;
    line-height: 68px;
    letter-spacing: -2px;
  }

  /* H3 */
  h3,
  .display-2 {
    font-size: 48px;
    line-height: 56px;
    letter-spacing: -1px;
  }

  /* H4 */
  h4,
  .display-1 {
    font-size: 34px;
    line-height: 42px;
  }

  /* H5 */
  h5,
  .headline {
    font-size: 24px;
    line-height: 32px;
  }

  /* H6 */
  h6,
  .title {
    font-size: 20px;
    font-weight: 400;
    letter-spacing: 0px;
    line-height: 28px;
    font-family: "Source Sans Pro", sans-serif;
  }

  /* Subtitle 1 */
  .subtitle-1 {
    font-size: 16px;
    font-weight: 400;
    letter-spacing: 0px;
    line-height: 24px;
    font-family: "Source Sans Pro", sans-serif;
  }

  /* Subtitle 2 */
  .subtitle-2 {
    font-size: 14px;
    font-weight: 400;
    letter-spacing: 0px;
    line-height: 22px;
    font-family: "Source Sans Pro", sans-serif;
  }

  /* Body 1 */
  .body-1 {
    font-size: 16px;
    font-weight: 400;
    letter-spacing: 0px;
    line-height: 24px;
    font-family: "Source Sans Pro", sans-serif;
  }

  /* Body 2 */
  .body-2 {
    font-size: 14px;
    font-weight: 400;
    letter-spacing: 0px;
    line-height: 22px;
    font-family: "Source Sans Pro", sans-serif;
  }

  /* Caption */
  .caption {
    font-size: 12px;
    font-weight: 400;
    letter-spacing: 0.5px;
    line-height: 16px;
    font-family: "Source Sans Pro", sans-serif;
  }

  /* Overline */
  .overline {
    font-size: 12px;
    font-weight: 700;
    letter-spacing: 1.5px;
    line-height: 16px;
    text-transform: uppercase;
    font-family: "Source Sans Pro", sans-serif;
  }

  > ul,
  > ol {
    margin-bottom: 12px;

    li {
      font-size: 16px;
      line-height: 24px;
      color: rgba(0, 0, 0, 0.6);
      opacity: 0.87;

      p {
        margin: 0;
      }
    }
  }

  > hr {
    margin-bottom: 12px;
  }

  > p {
    font-family: "Source Sans Pro";
    font-size: 16px;
    font-style: normal;
    font-weight: normal;
    line-height: 24px;
    color: rgba(0, 0, 0, 0.6);
    opacity: 0.87;

    &:last-of-type {
      margin-bottom: 12px;
    }

    // Activity - COMPANY_FIELD_SET
    > code {
      max-width: 1030px;
      padding: 8px 16px;
      margin-left: 4px;
      font-family: unset;
      font-size: 16px;
      font-weight: normal;
      line-height: 20px;
      color: white;
      text-align: center;
      background: $primary;
      border-radius: 16px;
      box-shadow: unset;

      @media (max-width: 1650px) {
        max-width: 990px;
      }

      @media (max-width: 1600px) {
        max-width: 950px;
      }

      @media (max-width: 1550px) {
        max-width: 900px;
      }

      @media (max-width: 1500px) {
        max-width: 890px;
      }

      @media (max-width: 1450px) {
        max-width: 850px;
      }

      @media (max-width: 1380px) {
        max-width: 800px;
      }

      @media (max-width: 1300px) {
        max-width: 750px;
      }

      @media (max-width: 1250px) {
        max-width: 700px;
      }

      @media (max-width: 1170px) {
        max-width: 650px;
      }

      @media (max-width: 1100px) {
        max-width: 600px;
      }

      @media (max-width: 1020px) {
        max-width: 550px;
      }

      @media (max-width: 959px) {
        max-width: 800px;
      }

      @media (max-width: 890px) {
        max-width: 750px;
      }

      @media (max-width: 840px) {
        max-width: 700px;
      }

      @media (max-width: 790px) {
        max-width: 650px;
      }

      @media (max-width: 740px) {
        max-width: 600px;
      }

      @media (max-width: 690px) {
        max-width: 550px;
      }

      @media (max-width: 640px) {
        max-width: 500px;
      }

      &::before,
      &::after {
        content: none;
      }
    }
  }

  a {
    color: rgba(0, 0, 0, 0.6);
    text-decoration-line: underline;

    &:hover {
      color: $primary;
    }
  }

  blockquote {
    margin: 14px 0;
    border-left: 4px solid #e5e5e5;
    padding: 0 16px;
    min-height: 20px;
    line-height: 24px;
    color: #999;
  }
}














































div.v-avatar {
  margin-right: 6px;

  &.placeholder {
    background: rgba(0, 0, 0, 0.12);

    i.avatar-placeholder {
      color: white;
    }
  }

  img.rounded-tile {
    border-radius: 4px;
  }

  &.autocomplete-search-avatar {
    width: 24px;
    height: auto;

    &.person {
      border: 2px solid #c4c4c4;

      .v-icon {
        color: #dadada;
      }
    }
  }

  &.field-avatar {
    border-radius: 50%;
  }
}
