div.unauthed-container {
  height: 100vh;
  min-height: 500px;

  div.signin-form-wrapper {
    border-radius: 8px;
    border: 1px solid rgba(255, 255, 255, 0.3) !important;
    background: rgba(202, 200, 236, 0.05) !important;
    box-shadow:
      0px 4px 6px 0px rgba(202, 200, 236, 0.05),
      0px 10px 15px 0px rgba(202, 200, 236, 0.1) !important;
    backdrop-filter: blur(10px);
    margin-bottom: 24px;
  }

  div.signin-form.v-card {
    width: 480px;
    margin: 4px;
    padding: 24px 36px 16px 36px;
    border-radius: 4px !important;
    border: 1px solid #ebebeb !important;
    background: #fff !important;
    box-shadow: none !important;
    text-align: center;

    div.v-card__text {
      padding: unset;
    }

    p {
      color: rgba(0, 0, 0, 0.6);
      font-family: "Source Sans Pro";
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: 20px;

      &.align-start {
        text-align: start;
      }
    }

    div.v-input {
      &:not(.consent-checkbox) {
        div.v-input__slot {
          border-radius: 4px;
          border: 1px solid #ebebeb;
          box-shadow: unset !important;
          margin-bottom: unset;
        }
      }

      label {
        color: #a0a0a0;
        font-family: "Source Sans Pro";
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 20px;
      }

      input,
      input[type="password"] {
        color: rgba(0, 0, 0, 0.6) !important;
        font-family: "Source Sans Pro", Helvetica, Arial, sans-serif;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 20px;
      }
    }

    @media (max-width: 500px) {
      width: auto;
    }

    button {
      float: right;
      width: 100%;

      &:not(.v-icon) {
        border-radius: 4px;
        border-color: none;
        background: #ffffff;
        box-shadow:
          0px 1px 2px 0px rgba(0, 0, 0, 0.06),
          0px 1px 3px 0px rgba(0, 0, 0, 0.1);
        color: rgba(0, 0, 0, 0.6);
        font-family: Inter;
        font-size: 14px;
        font-style: normal;
        font-weight: 500;
        line-height: 20px;
        text-transform: capitalize;
        letter-spacing: normal;
      }
    }

    div.v-avatar.logo {
      display: inline-block;
      margin-right: auto;
      margin-left: auto;
    }
  }

  a.reset-password,
  a.help-link {
    font-size: 14px;
    font-family: "Inter";
    font-weight: 500;
    text-decoration: underline;
    color: rgba(0, 0, 0, 0.2);
    margin-top: -30px;
    line-height: 20px !important;

    &.within-paragraph {
      color: rgba(0, 0, 0, 0.6);
      font-family: "Source Sans Pro";
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: 20px;
    }
  }

  form.form-inputs {
    padding-bottom: 16px;
  }

  div.auth-error {
    color: red !important;
  }

  div.signin-form-field {
    div.v-input__append-inner {
      button.v-icon {
        font-size: 16px;
        color: #a0a0a0;
      }
    }
  }
}
